/* 
/* This component checks the token expiration every minute. If the token expires within 10 minutes, 
/* a modal will appear informing the user that they cannot clear it. 
/* The modal will remain until the user clicks to refresh the token or logs out.
*/

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { checkTokenStatus } from './utils';
import api from 'helpers/api';
import { decryption } from 'cipher/cipher';
import ButtonSpinner from 'components/ButtonSpinner';

function TokenExpiryModel(props) {
    const navigate = useNavigate();

    const [modal, setModal] = useState(false);
    const [loading, setLoader] = useState(false);

    useEffect(() => {
        const intervalId = setInterval(() => {
            const secret = localStorage.getItem("secret");
            if (secret?.length > 4) {
                let tokenStatus = checkTokenStatus(secret.slice(4), 600);
                if (tokenStatus.expiringSoon || tokenStatus.expired) {
                    if (tokenStatus.expired) {
                        navigate('/logout')
                    }
                    setModal(true);
                }
            }
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    const handleLogout = () => {
        setModal(false);
        navigate('/logout')
    };

    const handleRefreshToken = async () => {
        try {
            setLoader(true);
            let id = props?.user?.user?.id;
            let url = (props?.user?.user?.role == "vendor") ? '/vendor/refreshtoken' : '/accounts/refreshtoken';
            if (id) {
                let response = await api.post(url, { id: id });
                let decryptData = await decryption(response.data);
                if (response.status === 200) {
                    localStorage.setItem("secret", decryptData?.secret);
                    setTimeout(() => {
                        setLoader(false);
                        setModal(false);
                    }, 3000);
                }
            }
        }
        catch (err) {
            navigate('/logout')
        }
    };

    return (
        <Modal isOpen={modal}>
            <ModalHeader>Session Expired</ModalHeader>
            <ModalBody>
                Your session has expired due to a token timeout. Please refresh your token to continue or log out to return to the login page. Once the token has fully expired, you will be logged out automatically.
            </ModalBody>
            <ModalFooter>
                <Button color={props?.user?.user?.role == "vendor" ? "success" : "primary"} className='d-flex align-items-center mx-3' onClick={handleRefreshToken} disabled={loading ? true : false}>
                    Refresh Token
                    {loading ? <ButtonSpinner loaderStyle={{ width: '14px', height: '14px', marginLeft: '4px' }} className='d-flex align-items-center' /> : null}
                </Button>{' '}
                <Button color="secondary" onClick={handleLogout}>
                    Logout
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default TokenExpiryModel;