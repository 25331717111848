import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import { Link } from "react-router-dom"
//i18n
import { withTranslation } from "react-i18next"
import SidebarContent from "./SidebarContent"


import logo from "../../assets/AppLogo/Logo Icon color@3x.png"
import logoWithText from "../../assets/logo/companyLogo.png"

class Sidebar extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <React.Fragment>

        <div className="vertical-menu">
          <div className="navbar-brand-box">
            <Link to="/" aria-label="logo" className="logo logo-light">
              <span className="logo-sm">
                <img src={logo} alt="logo" style={{ width: '65px', marginLeft: '-22px', height: "50px" }} loading="lazy"/>
              </span>
              <span className="logo-lg logo-style">
                <img src={logoWithText} alt="logo with text" style={{ width: '135px', marginLeft: '35px', marginTop: '2px' }} loading="lazy"/>
              </span>
            </Link>
          </div>
          <div data-simplebar className="h-100 sidebar-content">
            <SidebarContent />
          </div>
          <div className="sidebar-background"></div>
        </div>
      </React.Fragment>
    )
  }
}

Sidebar.propTypes = {
  layout: PropTypes.string,
  showMenu: PropTypes.bool
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}
export default connect(
  mapStateToProps,
  {}
)(withTranslation()(Sidebar))
